.container-banner {
  width: 100%;
  height: 9rem;
  background: url("https://cdn.garenanow.com/gstaticid/FF/deletion-account/banner_delete.jpg")
    center no-repeat;
  background-size: 100% 100%;
  position: relative;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  h1 {
    font-size: 2vw;
    font-weight: bold;
    color: $white;
    position: relative;
    top: -5%;
    text-align: center;
    @include dark-mode {
      color: $white;
    }
  }
}

@media only screen and (max-width: 599px) {
  .container-banner {
    background-size: cover;
    height: 8rem;
    h1 {
      font-size: 3vw;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .container-banner {
    height: 10rem;
    h1 {
      font-size: 2vw;
    }
  }
}

@media only screen and (min-width: 992px) {
  .container-banner {
    height: 33vh;
  }
}
