@import "./../mixins";
@import "./../variables";
.home {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: sans-serif;
	.title {
		font-size: 3vh;
		font-weight: bold;
		margin-bottom: 2vh;
		color: $black;
		@include dark-mode {
			color: $black;
		}
	}
	.container-form {
		border: 1px solid #e2e8f0;
		box-shadow: 2px 2px 2px 2px#e2e8f0;
		border-radius: 2vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 2vh;
		margin-bottom: 5vh;
		background-color: $white;
		@include dark-mode {
			background-color: $white;
			border: 1px solid #e2e8f0;
			box-shadow: 2px 2px 2px 2px#e2e8f0;
		}
		.form-group {
			margin-bottom: 2vh;
			width: 90%;
			height: 200%;
			label {
				color: $black;
				@include dark-mode {
					color: $black;
				}
			}

			.form-control {
				margin-top: 1vh;
				display: block;
				width: 100%;
				height: 20%;
				height: calc(1.5em + 0.75rem + 2px);
				padding: 0.375rem 0.75rem;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				color: #495057;
				background-color: $white;
				background-clip: padding-box;
				border: 1px solid #ced4da;
				border-radius: 0.25rem;
				user-select: none;
				@include dark-mode {
					color: #495057;
					background-color: $white;
					border: 1px solid #ced4da;
				}
			}

			input:disabled,
			input:read-only {
				background-color: #e9ecef;
				opacity: 1;
				@include dark-mode {
					background-color: #e9ecef;
					opacity: 1;
				}
			}
		}
		button {
			display: inline-block;
			font-weight: 400;
			text-align: center;
			vertical-align: middle;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background-color: initial;
			border: 1px solid transparent;
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			line-height: 1.5;
			border-radius: 0.25rem;
			transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;
			color: $white;
			background-color: #dc3545;
			border-color: #dc3545;
			cursor: pointer;
			@include dark-mode {
				transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
					box-shadow 0.15s ease-in-out;
				color: $white;
				background-color: #dc3545;
				border-color: #dc3545;
			}
		}
	}
}

@media only screen and (min-width: 568px) {
	.home {
		.title {
			font-size: 4vh;
		}
		.container-form {
			width: 55%;
		}
	}
}

@media only screen and (min-width: 568px) and (min-height: 568px) {
	.home {
		.title {
			font-size: 2.5vh;
		}
		.container-form {
			width: 65%;
		}
	}
}

@media only screen and (min-width: 992px) {
	.home {
		height: 100%;
		.title {
			font-size: 3vh;
		}
		.container-form {
			width: 40%;
		}
	}
}

// @media only screen and (min-width: 992px) and (min-height:){
//   .home {
//     height: 50vh;
//     .title {
//       font-size: 3vh;
//     }
//     .container-form {
//       width: 35%;
//     }
//   }
// }
