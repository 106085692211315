.container-header {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0vw;
  background-color: $white;
  img {
    width: 12rem;
    cursor: pointer;
  }
  img:hover {
    filter: brightness(2);
  }
  @include dark-mode {
    background-color: $white;
  }
}

@media only screen and (max-width: 599px) {
  .container-header {
    height: 3rem;
    img {
      width: 10rem;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .container-header {
    height: 2.5rem;
    justify-content: flex-start;
    padding-left: 5rem;
    img {
      width: 10rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .container-header {
    height: 3rem;
    padding-left: 10rem;
    justify-content: flex-start;
  }
}
