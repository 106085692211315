.container {
  width: 100%;
  height: 45vh;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5vw;

  h1 {
    text-align: center;
    font-size: 4vh;
    color: $black;
    line-height: 1.5;
    @include dark-mode {
      color: $black;
    }
  }

  button {
    background-color: red; /* Green */
    border: none;
    color: white;
    padding: 1vw 2vw;
    margin: 2vw 0 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 599px) {
  .container {
    h1 {
      font-size: 5vw;
    }
  }
}
