@import "./mixins";
@import "reset";
@import "./mixins";
@import "./variables";
@import "./components/Home.scss";
@import "./components/OTP.scss";
@import "./components/Progress.scss";
@import "./components/Header.scss";
@import "./components/Banner.scss";
@import "./components/modal";

.form-error {
  color: red;
}

html,
body {
  background-color: $white !important;
  color: $black;
}

@include dark-mode {
  .form-error {
    color: red;
  }
  html,
  body {
    background-color: $white !important;
    color: black;
  }
}
