.react-responsive-modal-overlay.overlay {
  padding: 0;
  overflow-y: hidden;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;

  .modal {
    width: 35vmax;
    max-width: none;
    height: 45vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: $white;
    border-radius: 2vh;
    background-size: 100% 100%;
    font-size: 3.5vmin;
    color: $black;
    font-family: sans-serif;
    box-shadow: none;
    animation: 0.15s slideInUp linear;
    @include dark-mode {
      background: $white;
      background-color: $white;
      color: $black;
    }
    //Error
    &.error {
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 4vmin;
          text-align: center;
          font-family: "Poppins", sans-serif;
          color: $black;
          @include dark-mode {
            color: $black;
          }
        }
      }
    }

    &.confirm {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        text-align: center;
        line-height: 1.3;
      }

      .btn-group {
        margin: 1vw 0 0;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          margin: 0 .5vw;
          background-color: red; /* Green */
          border: none;
          color: white;
          padding: .8vw 3vw;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 1vw;
        }
      }
    }
  }
}

.btn-hover:hover {
  filter: brightness(120%);
}
