.OTP {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  .container-otp {
    width: 90%;
    background-color: #e2e8f0;
    border-radius: 2vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 50%;
    padding: 3vh;
    box-shadow: 2px 2px 2px 2px#dce0e6;
    @include dark-mode {
      background-color: #e2e8f0;
      box-shadow: 2px 2px 2px 2px#dce0e6;
    }

    .title {
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center;
      color: $black;
      @include dark-mode {
        color: $black;
      }
    }
    .form-control {
      margin-top: 1vh;
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      user-select: none;
      // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      @include dark-mode {
        color: #495057;
        background-color: $white;
        border: 1px solid #ced4da;
      }
    }

    .countdown {
      font-size: 1.2rem;
      color: $black;
      @include dark-mode {
        color: $black;
      }
    }
    button {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: initial;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: $white;
      background-color: #dc3545;
      border-color: #dc3545;
      cursor: pointer;
      @include dark-mode {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: $white;
        background-color: #dc3545;
        border-color: #dc3545;
      }
    }
  }
}

@media only screen and (min-width: 568px) {
  .OTP {
    .container-otp {
      width: 55%;
      height: 75%;
    }
  }
}

@media only screen and (min-width: 992px) {
  .OTP {
    height: 55vh;
    .container-otp {
      width: 35%;
      height: 60%;
    }
  }
}
